import React from 'react'
//import { StaticImage as Img } from 'gatsby-plugin-image';
import Content from '../Style/styleTwo'
import { SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import image from "~assets/image/media/LinkedinAutomation/SVG/Create-powerful-drip-campaigns.svg";

export default function Create({}){

return(
<Content>
  
  <Container>
    <Content.Inner>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
        <Content.TextBlock>
          <Content.Title as="h2"> <SuperTag value="Create powerful drip campaigns"/> </Content.Title>

          <Content.Text> <SuperTag value="Never let another lead slip through the cracks with a We-Connect smart campaign. Upload your leads from a LinkedIn search or CSV file, customize your own campaign sequence, and set advanced filters to ramp up your campaign."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Get Started for Free</Content.Button>
        </Content.TextBlock>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
        <Content.Image> 
        <img height="435" className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
        </Content.Image>
      </Col>
    </Row>
    </Content.Inner>
  </Container>
</Content>


)
}