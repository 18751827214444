import React from 'react'
import Hero from './style'
import { Col, Row, Container  } from 'react-bootstrap'
import { SuperTag } from '~components'
import img from '~assets/image/media/LinkedinAutomation/SVG/Maximize-your-prospecting.svg'
import { Link } from 'gatsby'
export default function HeroSection({backgroundColor,colClasses,...rest}){
return(
<Hero {...rest}>
  <Container>
    <Row className="row justify-content-center text-center">
      <Col className={colClasses ? colClasses : "col-xl-8"}>
        <Hero.Title as="h1">Maximize your prospecting on LinkedIn with We-Connect <br className="d-none d-xs-none d-lg-none d-xl-block" /></Hero.Title>
        <p>The most advanced LinkedIn outreach tool designed to boost your lead generation efforts on LinkedIn and close more deals. Sign up today to double your conversion rate.</p>
        <Hero.Image>
        <img className='w-100' src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </Hero.Image>
        <p></p>
        <Link target="_blank"  to="//account.we-connect.io/signup"><Hero.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded={true} sizeX="180px" sizeY="56px">Try it for free</Hero.Button></Link>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}